import React from "react"
import Layout from "@/components/layout"
import BolsaForm from "@/components/PesquisaDeAvaliacaoForm"

const PesquisaDeAvaliacaoPage = () => {
  return (
    <Layout>
      <BolsaForm />
    </Layout>
  );
};

export default PesquisaDeAvaliacaoPage;
